<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <p-divider align="left">
    <b>{{ moduleString + '.generalInformation' | translate }}</b>
  </p-divider>
  <!-- <div class="flex justify-content-between">
  <h4 class="mb-0">General Information</h4>
</div> -->

  <div class="formgrid grid p-fluid pt-2">
    <div class="col-12">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="canEditField"
      ></app-basic-input>
    </div>

    <!-- <div class="col-12 md:col-6">
  <app-basic-input label="Code" [placeholder]="'Enter Code'" [control]="formGroup?.controls?.code"
  [viewMode]="fieldViewMode"></app-basic-input>
</div> -->

    <div class="col-12 md:col-12">
      <app-text-editor
        [height]="'100px'"
        [advanced]="true"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="canEditField"
      ></app-text-editor>
    </div>
  </div>

  <p-divider align="left">
    <b>{{ moduleString + '.policyDetails' | translate }}</b>
  </p-divider>
  <!-- <div class="flex justify-content-between">
<h4 class="mb-0">Policy Details</h4>
</div> -->

  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-12 mt-2">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Enter Owner'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="canEditField"
      >
      </app-user-and-group-selector>
      <!-- <app-entity-category-tree-selector [label]="'Owner'" [categoryList]="categoryList"
    [placeholder]="'Search Entities'" [control]="formGroup?.controls?.owner" [multi]="false"
    [selectedEntities]="[data?.owner]" [viewMode]="fieldViewMode=='create'?'create':'view'" [dropDown]="true">
  </app-entity-category-tree-selector> -->
    </div>

    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [principleModeSelection]="'SELECTION'"
        [label]="moduleFieldString + '.reviewers.label' | translate: 'Reviewers'"
        [placeholder]="moduleFieldString + '.reviewers.placeholder' | translate: 'Enter Reviewers'"
        [control]="formGroup?.controls?.reviewers"
        [multi]="true"
        [viewMode]="canEditField"
        [selectedEntities]="data?.reviewers"
        [useResponsibilityMode]="true"
      >
      </app-user-and-group-tree-selector>
      <!-- <app-user-and-group-selector
  [label]="'Reviewers'"
  [placeholder]="'Search...'"
  [principleMode]="true"
  [principleModeSelection]="'SELECTION'"
  [control]="formGroup?.controls?.reviewers"
  [optionValue]="undefined"
  [multi]="true"
  [dropdown]="true"
  [viewMode]="fieldViewMode=='create'?'create':'view'"
  >
</app-user-and-group-selector> -->
      <!-- <app-entity-category-tree-selector [label]="'Reviewers'" [categoryList]="categoryList"
[placeholder]="'Search Entities'" [control]="formGroup?.controls?.reviewers" [multi]="true"
[viewMode]="fieldViewMode=='create'?'create':'view'" [selectedEntities]="data?.reviewers" [dropDown]="true"
[useResponsibilityMode]="true"
[dataType]="dataTypeEnum.ResponsibilityWithAllOneMode"
>
</app-entity-category-tree-selector> -->
    </div>

    <div class="col-12 md:col-6">
      <app-user-and-group-tree-selector
        [principleModeSelection]="'SELECTION'"
        [label]="moduleFieldString + '.approvers.label' | translate: 'Approvers'"
        [placeholder]="moduleFieldString + '.approvers.placeholder' | translate: 'Enter Approvers'"
        [control]="formGroup?.controls?.approvers"
        [multi]="true"
        [viewMode]="canEditField"
        [selectedEntities]="data?.approvers"
        [useResponsibilityMode]="true"
      >
      </app-user-and-group-tree-selector>
      <!-- <app-user-and-group-selector
  [label]="'Approvers'"
  [placeholder]="'Search...'"
  [principleMode]="true"
  [principleModeSelection]="'SELECTION'"
  [control]="formGroup?.controls?.approvers"
  [optionValue]="undefined"
  [multi]="true"
  [dropdown]="true"
  [viewMode]="fieldViewMode=='create'?'create':'view'"
  >
</app-user-and-group-selector> -->
      <!-- <app-entity-category-tree-selector [label]="'Approvers'" [categoryList]="categoryList"
[placeholder]="'Search Entities'" [control]="formGroup?.controls?.approvers"
[selectedEntities]="data?.approvers" [multi]="true" [viewMode]="fieldViewMode=='create'?'create':'view'"
[dropDown]="true"
[useResponsibilityMode]="true"
[dataType]="dataTypeEnum.ResponsibilityWithAllOneMode"
>
</app-entity-category-tree-selector> -->
    </div>
    @if (data?.policyStatus == 'PUBLISHED') {
      <div class="col-12 md:col-6">
        <app-basic-input
          [label]="moduleFieldString + '.publishedBy.label' | translate: 'Published By'"
          [placeholder]="moduleFieldString + '.publishedBy.placeholder' | translate: 'Enter Published By'"
          [control]="approvedActionDetailsForm?.controls?.user"
          [viewMode]="'view'"
        ></app-basic-input>
      </div>
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="moduleFieldString + '.publishedAt.label' | translate: 'Published At'"
          [placeholder]="moduleFieldString + '.publishedAt.placeholder' | translate: 'Enter Published At'"
          [name]="'date'"
          [showIcon]="false"
          [control]="approvedActionDetailsForm?.controls?.date"
          [viewMode]="'view'"
        ></app-date-input>
      </div>
    }
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.validFrom.label' | translate: 'Valid From'"
        [placeholder]="'2022-11-22'"
        [name]="'validFrom'"
        [showIcon]="true"
        [control]="formGroup?.controls?.validFrom"
        [viewMode]="canEditField"
        [maxDate]="formGroup?.controls?.validTo?.value || null"
      ></app-date-input>
    </div>

    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.validTo.label' | translate: 'Valid To'"
        [placeholder]="'2022-11-22'"
        [name]="'validTo'"
        [showIcon]="true"
        [control]="formGroup?.controls?.validTo"
        [viewMode]="canEditField"
        [minDate]="formGroup?.controls?.validFrom?.value || null"
      ></app-date-input>
    </div>
    <div class="col-12">
      <app-switch-input
        [label]="moduleFieldString + '.allowExceptions.label' | translate: 'Allow Exceptions'"
        [placeholder]="moduleFieldString + '.allowExceptions.placeholder' | translate: 'Enter Allow Exceptions'"
        [name]="'allowExceptions'"
        [control]="formGroup?.controls?.allowExceptions"
        [viewMode]="canEditField"
      >
      </app-switch-input>
    </div>

    @if (formGroup?.controls?.allowExceptions?.value) {
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="moduleFieldString + '.exceptionDurationFrom.label' | translate: 'Exception Duration From'"
          [placeholder]="'2022-11-22'"
          [name]="'exceptionDuration'"
          [showIcon]="true"
          [control]="formGroup?.controls?.exceptionDurationFrom"
          [viewMode]="canEditField"
          [minDate]="formGroup?.controls?.validFrom?.value || null"
          [maxDate]="formGroup?.controls?.exceptionDurationTo?.value || formGroup?.controls?.validTo?.value || null"
        ></app-date-input>
      </div>
    }
    @if (formGroup?.controls?.allowExceptions?.value) {
      <div class="col-12 md:col-6">
        <app-date-input
          [label]="moduleFieldString + '.exceptionDurationTo.label' | translate: 'Exception Duration To'"
          [placeholder]="'2022-11-22'"
          [name]="'exceptionDurationTo'"
          [showIcon]="true"
          [control]="formGroup?.controls?.exceptionDurationTo"
          [viewMode]="canEditField"
          [minDate]="formGroup?.controls?.exceptionDurationFrom?.value || formGroup?.controls?.validFrom?.value || null"
          [maxDate]="formGroup?.controls?.validTo?.value || null"
        ></app-date-input>
      </div>
    }

    <div class="col-12">
      <app-list-strings
        [label]="moduleFieldString + '.externalReferences.label' | translate: 'External References'"
        [placeholder]="moduleFieldString + '.externalReferences.placeholder' | translate: 'Add References'"
        class="mb-2 col-span-2"
        formControlName="externalReferences"
        name="options"
        [viewMode]="canEditField"
      ></app-list-strings>
    </div>
    <!-- <div *ngIf="fieldViewMode != 'create'" class="col-12">
<app-basic-input
  label="Policy Body name"
  [placeholder]="'name'"
  [control]="policyBodyForm?.controls?.label"
  [viewMode]="'view'"
></app-basic-input>
</div>
<div *ngIf="fieldViewMode != 'create'" class="col-12 md:col-12">
  <app-text-editor
    [height]="'500px'"
    [advanced]="true"
    [name]="'textarea'"
    label="Policy Body"
    [placeholder]="'Enter Text...'"
    [control]="policyBodyForm?.controls?.body"
    [viewMode]="'view'"
  ></app-text-editor>
</div> -->
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton && (data?.policyStatus == 'DRAFT' || !data?.policyStatus)) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton && (data?.policyStatus == 'DRAFT' || !data?.policyStatus)) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton && (data?.policyStatus == 'DRAFT' || !data?.policyStatus)) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
