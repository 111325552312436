import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForm, DataTypeEnum, EntityTypeFieldDto, ModuleKeywords, PolicyDto } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';
import { PolicyDataService } from 'app/modules/org-framework/service/data/PolicyDataService';

@Component({
  selector: 'app-policy-item-form',
  templateUrl: './policy-item-form.component.html',
  styleUrls: ['./policy-item-form.component.scss'],
})
export class PolicyItemFormComponent extends BaseForm<PolicyDto> implements OnInit {
  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];
  dataTypeEnum = DataTypeEnum;
  formVal;
  approvedActionDetailsForm = new FormGroup({
    user: new FormControl({ value: null, disabled: true }),
    date: new FormControl({ value: null, disabled: true }),
    oldState: new FormControl({ value: null, disabled: true }),
    newState: new FormControl({ value: null, disabled: true }),
  });
  // policyBodyForm:FormGroup ;
  constructor(
    public viewModeService: ViewModeService,
    public policyDataService: PolicyDataService,
    private router: Router
  ) {
    super(viewModeService, ModuleKeywords.Policy);
  }

  ngOnInit(): void {
    this.formVal = this.formValid$;
    setTimeout(() => {
      this.formValid$.subscribe((res) => {});
    }, 10000);
  }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    // this.policyBodyForm.patchValue({...data.policyBody});
    this.approvedActionDetailsForm.patchValue({ ...data?.approvedActionDetails });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      validFrom: new FormControl(null),
      validTo: new FormControl(null),
      owner: new FormControl(null, Validators.required),
      owningGroup: new FormControl(null),
      approvers: new FormControl(null, Validators.required),
      reviewers: new FormControl(null, Validators.required),
      externalReferences: new FormControl(null),
      exceptionDurationFrom: new FormControl(null),
      exceptionDurationTo: new FormControl(null),
      allowExceptions: new FormControl(null),
    });
    // this.policyBodyForm = new FormGroup({
    //     body: new FormControl(null),
    //     label: new FormControl(null),
    // })
  }
  // resetFormGroup() {
  //   this.formGroup.reset();
  // }

  getViewMode() {
    return this.fieldViewMode != 'view' && (this.data?.policyStatus == 'DRAFT' || !this.data?.policyStatus)
      ? this.fieldViewMode
      : 'view';
  }
  get canEditField() {
    return this?.data && this?.data?.policyStatus != 'DRAFT' ? 'view' : this.fieldViewMode;
  }
}
